#viewer {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* #renderCanvas {
  width: 100%;
  height: 100%;
} */

canvas {
  background-color: white;
  width: 100%;
  height: 100%;
}
